// Libs
import { NavLink, useLocation } from 'react-router-dom';

import { useState, useEffect } from 'react';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { subDays, parseISO } from 'date-fns';

// MUI Components
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// MUI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CopyAllIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Face6Icon from '@mui/icons-material/Face6';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LinkOffIcon from '@mui/icons-material/LinkOffOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

// Local Components
import AddPassageContextMenu from './AddPassageContextMenu';
import AppearingPassageVersion from './AppearingPassageVersion';
import { useUpdatePassageVersion, useDeletePassageVersion } from './mutations';
import ContextMenu from 'components/ContextMenu';
import { ConfirmDialog } from 'components/Forms';
import MarkdownPreview from 'components/MarkdownPreview';
import DefectToolTip from 'components/Rules/DefectToolTip';
import DeleteRuleVersionConfirmation from 'components/Rules/DeleteRuleVersionConfirmation';

// Contexts & Hooks
import useRoutes from 'hooks/useRoutes';

// Utils
import { makeArray } from 'utils/enumerable';
import formatDate from 'utils/formatDate';
import { toISODateString } from 'utils/dateAndTime';
import { parentTypeName, typeName, passageContent } from 'utils/passages';

// Hooks
import useCopyToClipboard from 'hooks/useCopyToClipboard';

const ENRICHMENT_STATUS = {
  defective: {
    icon: <ErrorOutlineIcon fontSize="small" sx={{ color: 'red.900' }} />,
    title: 'Associated Defect Present',
    color: 'red.900',
  },
  completed: {
    icon: <CheckCircleOutlineIcon fontSize="small" sx={{ color: 'green.700' }} />,
    title: 'Auto Enrichement Completed',
    color: 'green.700',
  },
  manual: {
    icon: <Face6Icon fontSize="small" />,
    title: 'Manually Processed',
  },
  errored: {
    icon: <ErrorOutlineIcon fontSize="small" sx={{ color: 'red.900' }} />,
    title: 'Auto Enrichment Errored',
    color: 'red.900',
  },
  queued: {
    icon: <PublishedWithChangesIcon fontSize="small" />,
    title: 'Auto Enrichment Queued',
  },
  started: {
    icon: <AutoModeIcon fontSize="small" />,
    title: 'Auto Enrichment Started',
  },
  importing: {
    icon: <ImportExportIcon fontSize="small" />,
    title: 'Auto Enrichment Importing',
  },
};

const canDragPassageVersion = (passageVersion, ruleVersion) =>
  !passageVersion.previousVersion && ruleVersion.startsAt === passageVersion.startsAt;

const RuleVersionExplorerView = ({
  ruleVersions = [],
  ruleVersion,
  rule,
  fetchRuleVersionError,
  fetchAppearingPassageVersionsError,
  appearingPassageVersions = [],
  detachedPassageVersions = [],
  automaticEnrichment,
  createPassage,
  updatePassagePosition,
  handlePassageVersionEdit,
  refetchQueries,
  fetchRuleError,
  handleRuleVersionCreate,
  handleRuleVersionEdit,
  createPassageVersion,
}) => {
  const sx = useSx();
  const copyToClipboard = useCopyToClipboard();

  const [isDetachedDrawerOpen, setIsDetachedDrawerOpen] = useState(false);
  const [dndPassageVersions, setDndPassageVersions] = useState(appearingPassageVersions);

  const [dialogDetails, setDialogDetails] = useState({
    title: '',
    description: '',
    children: null,
    confirmCallback: () => () => {},
  });

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogSubmitting, setDialogSubmitting] = useState(false);

  const hasDetachedItems = !!detachedPassageVersions.length;
  const handleCopy = (message, text) => copyToClipboard(text, message);
  const onToggleDetachedDrawer = () =>
    hasDetachedItems && setIsDetachedDrawerOpen(!isDetachedDrawerOpen);

  const deletePassageVersion = useDeletePassageVersion();
  const updatePassageVersion = useUpdatePassageVersion();

  const [isDeleteRuleVersionDialogOpen, setDeleteRuleVersionDialogOpen] = useState(false);
  const onClose = () => setDeleteRuleVersionDialogOpen(false);

  const onDragEnd = async ({ source, destination }) => {
    if (!destination || source.index === destination.index) return;

    const destDragItem = dndPassageVersions[destination.index];

    const dragItems = Array.from(dndPassageVersions);
    const [srcDragItem] = dragItems.splice(source.index, 1);

    dragItems.splice(destination.index, 0, srcDragItem);
    setDndPassageVersions(dragItems);

    const status = await updatePassagePosition(
      srcDragItem.passage.id,
      srcDragItem.passage.__typename,
      destDragItem.passage.position
    );

    if (!status) {
      setDndPassageVersions(appearingPassageVersions);
    }
  };

  useEffect(() => {
    setDndPassageVersions(appearingPassageVersions);
  }, [appearingPassageVersions]);

  const currentRuleVersionIndex = () =>
    ruleVersions.findIndex((version) => version.id === ruleVersion.id);

  const onDialogCancel = () => setDialogOpen(false);
  const onDialogConfirm = async () => {
    setDialogSubmitting(true);
    await dialogDetails.confirmCallback();
    setDialogSubmitting(false);
    setDialogOpen(false);
  };

  const dialogContent = (passageVersion) => {
    return {
      attach: {
        title: `Attach ${typeName(passageVersion)} Version`,
        description:
          'You are about to attach this version to this Rule Version. Are you sure you want to do this?',
        confirmCallback: async () => {
          await updatePassageVersion({
            ...passageVersion,
            startsAt: ruleVersion.startsAt,
            endsAt: null,
          });

          if (detachedPassageVersions.length <= 1) {
            setIsDetachedDrawerOpen(false);
          }
        },
      },
      detach: {
        title: `Detach ${typeName(passageVersion)} Version`,
        description:
          'You are about to detach this version from this Rule Version. Are you sure you want to do this?',
        confirmCallback: async () =>
          await updatePassageVersion({ ...passageVersion, detachOverride: true, endsAt: null }),
      },
      expire: {
        title: `Expire ${typeName(passageVersion)} Version`,
        description: 'You are about to expire this version. Are you sure you want to do this?',
        confirmCallback: async () => {
          let expirationDate =
            ruleVersions[currentRuleVersionIndex() + 1].endsAt ||
            toISODateString(subDays(parseISO(ruleVersion.startsAt), 1));

          await updatePassageVersion({
            ...passageVersion,
            endsAt: expirationDate,
          });
        },
      },
      unexpire: {
        title: `Unexpire ${typeName(passageVersion)} Version`,
        description: 'You are about to unexpire this version. Are you sure you want to do this?',
        confirmCallback: async () =>
          await updatePassageVersion({ ...passageVersion, endsAt: null }),
      },
      delete: {
        title: `Delete ${typeName(passageVersion)}`,
        description: 'You are about to delete this version.  Are you sure you want to do this?',
        confirmCallback: async () => {
          await deletePassageVersion(passageVersion);

          if (detachedPassageVersions.length <= 1) {
            setIsDetachedDrawerOpen(false);
          }
        },
        children: passageVersion.passage.versions.pageInfo.totalCount === 1 && (
          <Alert severity="error" sx={sx.dialogAlert}>
            Warning: This is the only version of this resource. Deleting it will also delete the
            resource.
          </Alert>
        ),
      },
    };
  };

  const openPassageDialog = (action, passageVersion) => {
    setDialogDetails(dialogContent(passageVersion)[action]);
    setDialogOpen(true);
  };

  const isLastRuleVersion = ruleVersion.nextVersion === null;

  return (
    <Box sx={sx.root}>
      <Box sx={sx.ruleVersionListRow}>
        <Box sx={sx.ruleVersionList}>
          <Box sx={sx.tabsWrapper}>
            <ScrollableTabs
              rule={rule}
              ruleVersions={ruleVersions}
              currentVersionId={ruleVersion.id}
            />
          </Box>
          <Button
            sx={sx.addVersionButton}
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleRuleVersionCreate}
            disabled={automaticEnrichment && !isLastRuleVersion}
          >
            Add Rule Version
          </Button>
        </Box>
        <Box sx={sx.detachedToggleContainer(hasDetachedItems)}>
          <Box
            sx={sx.detachedToggle}
            onClick={onToggleDetachedDrawer}
            data-testid="detached-toggle"
          >
            <LinkOffIcon />
            <Typography sx={sx.detachedCount}>
              {hasDetachedItems ? detachedPassageVersions.length : 0}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={sx.contentRow}>
        {fetchRuleError || fetchRuleVersionError || fetchAppearingPassageVersionsError ? (
          <Alert severity="error">Something went wrong :(</Alert>
        ) : (
          <>
            <Box sx={sx.ruleVersionText} data-testid="rule-version-text">
              <Box sx={sx.columnHeader}>Rule Version</Box>
              <Box sx={sx.versionNode}>
                <Box sx={sx.contentColumn}>
                  <MarkdownPreview text={ruleVersion.text} />
                </Box>
                <Box sx={sx.dragColumn} />
                <Box sx={sx.defectColumn}>
                  <DefectToolTip sx={sx.smallIcon} defects={ruleVersion.defects} />
                </Box>
                <Box sx={sx.contextColumn}>
                  <RuleVersionContextMenu
                    ruleVersion={ruleVersion}
                    handleCopy={handleCopy}
                    automaticEnrichment={automaticEnrichment}
                    handleRuleVersionEdit={handleRuleVersionEdit}
                    onRuleVersionDelete={() => setDeleteRuleVersionDialogOpen(true)}
                  />
                  <DeleteRuleVersionConfirmation
                    isOpen={isDeleteRuleVersionDialogOpen}
                    onClose={onClose}
                    refetchQueries={refetchQueries}
                    ruleId={rule.id}
                    versionId={ruleVersion.id}
                    versionsCount={ruleVersions.length}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={sx.appearingPassageVersions} data-testid="appearing-passages">
              <Box sx={sx.columnHeader}>R/SI Versions</Box>

              {dndPassageVersions.length === 0 ? (
                <Box sx={sx.noContentContainer}>
                  <Typography mb={1}>
                    There are no R/SI versions to show for this rule version
                  </Typography>
                  <AddPassageContextMenu
                    rule={rule}
                    ruleVersion={ruleVersion}
                    disabled={automaticEnrichment}
                    icon={<AddIcon sx={sx.addPassageIcon(automaticEnrichment)} />}
                  />
                </Box>
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="passage-versions-droppable">
                    {({ placeholder, innerRef, droppableProps }) => (
                      <Box ref={innerRef} {...droppableProps}>
                        {dndPassageVersions.map((passageVersion, index) => {
                          const showDragIcon = canDragPassageVersion(passageVersion, ruleVersion);

                          return (
                            <Draggable
                              key={passageVersion.id}
                              index={index}
                              draggableId={passageVersion.id}
                              isDragDisabled={!showDragIcon || automaticEnrichment}
                            >
                              {(dndProps) => (
                                <AppearingPassageVersion
                                  dndProps={dndProps}
                                  passageVersion={passageVersion}
                                  appearingPassageVersions={appearingPassageVersions}
                                  rule={rule}
                                  ruleVersion={ruleVersion}
                                  index={index}
                                  createPassage={createPassage}
                                  createPassageVersion={createPassageVersion}
                                  openPassageDialog={openPassageDialog}
                                  handlePassageVersionEdit={handlePassageVersionEdit}
                                />
                              )}
                            </Draggable>
                          );
                        })}
                        {placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Box>
          </>
        )}
        <Box sx={sx.detachedDrawer(isDetachedDrawerOpen, hasDetachedItems)}>
          {isDetachedDrawerOpen && (
            <Box data-testid="detached-passages">
              <Box sx={sx.detachedColumnHeader}>Detached R/SI Versions</Box>
              {detachedPassageVersions.map((passageVersion) => (
                <Box key={passageVersion.id} sx={sx.versionNode}>
                  <Box sx={sx.contentColumn}>
                    <MarkdownPreview text={passageContent(passageVersion)} />
                  </Box>
                  <Box sx={sx.contextColumn}>
                    <DetachedPassageVersionContextMenu
                      passageVersion={passageVersion}
                      handleCopy={handleCopy}
                      openPassageDialog={openPassageDialog}
                      automaticEnrichment={automaticEnrichment}
                      handlePassageVersionEdit={handlePassageVersionEdit}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmDialog
        isOpen={isDialogOpen}
        isSubmitting={dialogSubmitting}
        title={dialogDetails.title}
        description={dialogDetails.description}
        onConfirm={onDialogConfirm}
        onCancel={onDialogCancel}
        confirmText={'Confirm'}
      >
        {dialogDetails.children}
      </ConfirmDialog>
    </Box>
  );
};

const ScrollableTabs = ({ rule, ruleVersions, currentVersionId }) => {
  const sx = useSx();
  const routes = useRoutes();
  const location = useLocation();

  const { id: ruleId, regulatorId } = rule;

  const ruleVersionStatus = (ruleVersion) => {
    if (ruleVersion.defects?.pageInfo?.totalCount > 0) return 'defective';

    return ruleVersion.enrichmentStatus || 'manual';
  };

  return (
    <Tabs
      value={currentVersionId}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable tabs"
      indicatorColor="secondary"
      sx={sx.tabs}
      TabScrollButtonProps={{
        sx: sx.tabScrollBtn,
      }}
    >
      {ruleVersions
        .slice()
        .reverse()
        .map((ruleVersion) => {
          const { title, icon, color } = ENRICHMENT_STATUS[ruleVersionStatus(ruleVersion)];

          const ruleVersionId = ruleVersion.id;

          return (
            <Tab
              component={NavLink}
              key={ruleVersionId}
              value={ruleVersionId}
              aria-current={ruleVersionId === currentVersionId && 'page'}
              label={formatDate(ruleVersion.startsAt, 'yyyy MMM d')}
              icon={
                <Tooltip placement="top" title={title}>
                  {icon}
                </Tooltip>
              }
              to={routes.generateUrl('regulator.rule.ruleVersion', {
                regulatorId,
                ruleId,
                ruleVersionId,
                params: location.search,
              })}
              iconPosition="start"
              sx={sx.tab(color)}
            />
          );
        })}
    </Tabs>
  );
};

const DetachedPassageVersionContextMenu = ({
  passageVersion,
  handleCopy,
  openPassageDialog,
  automaticEnrichment,
  handlePassageVersionEdit,
}) => {
  const title = `Detached Passage Version ${typeName(passageVersion)}`;
  const subtitles = [passageVersion.number];

  return (
    <ContextMenu dense identifier={passageVersion.id} title={title} subtitles={subtitles}>
      <MenuItem
        data-testid="passage-version-copy-passage-id"
        onClick={() =>
          handleCopy(`Copied ${parentTypeName(passageVersion)} ID`, passageVersion.passage?.id)
        }
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy {parentTypeName(passageVersion)} ID</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="passage-version-copy-id"
        onClick={() => handleCopy(`Copied ${typeName(passageVersion)} ID`, passageVersion.id)}
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy {typeName(passageVersion)} ID</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="passage-version-copy-text"
        onClick={() => handleCopy(`Copied ${typeName(passageVersion)} Text`, passageVersion.text)}
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy {typeName(passageVersion)} Text</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        data-testid="passage-version-edit"
        disabled={automaticEnrichment}
        onClick={() => handlePassageVersionEdit(passageVersion)}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>Edit Version</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={automaticEnrichment}
        data-testid="passage-version-delete"
        onClick={() => openPassageDialog('delete', passageVersion)}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Delete Version</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        data-testid="passage-version-attach"
        disabled={automaticEnrichment}
        onClick={() => openPassageDialog('attach', passageVersion)}
      >
        <ListItemIcon>
          <AddLinkIcon />
        </ListItemIcon>
        <ListItemText>Attach to Current Rule Version</ListItemText>
      </MenuItem>
    </ContextMenu>
  );
};

const RuleVersionContextMenu = ({
  ruleVersion,
  handleCopy,
  automaticEnrichment,
  onRuleVersionDelete,
  handleRuleVersionEdit,
}) => {
  const title = 'Rule Version';
  const subtitles = [
    ruleVersion.number,
    `${ruleVersion.startsAt}${ruleVersion.endsAt ? ` - ${ruleVersion.endsAt}` : ''}`,
  ];

  return (
    <ContextMenu dense identifier={ruleVersion.id} title={title} subtitles={subtitles}>
      <MenuItem
        data-testid="rule-version-copy-id"
        onClick={() => handleCopy('Copied Rule Version ID', ruleVersion.id)}
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy Rule Version ID</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="rule-version-copy-text"
        onClick={() => handleCopy('Copied Rule Version Text', ruleVersion.text)}
      >
        <ListItemIcon>
          <CopyAllIcon />
        </ListItemIcon>
        <ListItemText>Copy Rule Version Text</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        data-testid="rule-version-edit"
        disabled={automaticEnrichment}
        onClick={handleRuleVersionEdit}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>Edit This Version</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="rule-version-delete"
        disabled={automaticEnrichment}
        onClick={onRuleVersionDelete}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Delete Version</ListItemText>
      </MenuItem>
    </ContextMenu>
  );
};

const useSx = () => {
  const fixedColumn = {
    display: 'flex',
    width: 24,
    flexShrink: 0,
    justifyContent: 'center',
    marginTop: '0.5em',
  };

  const halfMarginAbove = { marginTop: '0.5em' };
  const halfMarginBelow = { marginBottom: '0.5em' };

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    ruleVersionListRow: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    ruleVersionList: {
      display: 'flex',
      maxWidth: 'calc(100% - 70px)',
      flex: 2,
      alignItems: 'center',
    },
    tabsWrapper: {
      flexGrow: 0,
      flex: 1,
      overflow: 'hidden',
    },
    addVersionButton: {
      whiteSpace: 'nowrap',
      flexShrink: 1,
      padding: '0 1em',
    },
    tabs: {
      margin: 0,
      minHeight: 0,
      '& .MuiTab-root': {
        padding: '0.6em',
        minHeight: 0,
      },
    },
    tab: (color) => ({
      color: color || 'gray.500',
      textTransform: 'none',
      fontWeight: '600',
      '&.Mui-selected': {
        fontWeight: '900',
        color: color || 'gray.700',
      },
    }),
    tabScrollBtn: {
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    },
    detachedToggleContainer: (hasItems) => ({
      display: 'flex',
      color: 'secondary.contrastText',
      backgroundColor: hasItems ? 'red.900' : 'gray.400',
      borderLeft: '8px solid',
      borderRight: '8px solid',
      borderColor: hasItems ? 'red.900' : 'gray.400',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      flexShrink: 0,
      width: 70,
      fontSize: '0.83em',
    }),
    detachedToggle: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.1em',
      padding: '0.5em 0.7em 0.5em 0.2em',
      cursor: 'pointer',
    },
    detachedCount: {
      marginLeft: '0.5em',
      fontWeight: 'bold',
    },
    dialogAlert: {
      marginTop: '1em',
    },
    contentRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    noContentContainer: {
      display: 'flex',
      margin: '1.3rem auto',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addPassageIcon: (disabled) => ({
      padding: '0.2rem',
      fontSize: '1.5rem',
      color: 'white',
      borderRadius: '50%',
      backgroundColor: disabled ? 'grey.400' : 'green.700',
    }),
    ruleVersionText: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      flexGrow: 1,
      borderBottom: '8px solid',
      borderBottomColor: 'gray.300',
      borderLeft: '8px solid',
      borderLeftColor: 'gray.300',
    },
    appearingPassageVersions: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      flexGrow: 1,
      borderBottom: '8px solid',
      borderBottomColor: 'gray.300',
      borderLeft: '8px solid',
      borderLeftColor: 'gray.300',
    },
    columnHeader: {
      padding: 1,
      paddingTop: 2,
      backgroundColor: 'gray.300',
      fontWeight: 'bold',
    },
    versionNode: {
      display: 'flex',
      borderColor: 'gray.300',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: '1px',
      marginBottom: '-1px',
      position: 'relative',
      '&:last-child': {
        borderBottom: 0,
      },
    },
    defectColumn: {
      ...fixedColumn,
    },
    dragColumn: {
      ...fixedColumn,
      marginTop: 0,
      flexDirection: 'column',
      alignItems: 'center',
    },
    contextColumn: {
      ...fixedColumn,
    },
    spacerColumn: {
      ...fixedColumn,
    },
    borderColumn: {
      ...fixedColumn,
      marginTop: 0,
      borderLeft: '16px solid white',
      borderRight: '8px solid',
      borderRightColor: 'gray.300',
      marginBottom: '1px',
    },
    contentColumn: {
      flexGrow: 1,
      paddingLeft: '8px',
      // Targets the absolute first child, up to 10 levels deep
      ...makeArray(10).reduce((acc, _, idx) => ({
        ...acc,
        ['&' + ' > :first-child'.repeat(idx + 1)]: halfMarginAbove,
      })),
      // Targets the absolute last child, up to 10 levels deep
      ...makeArray(10).reduce((acc, _, idx) => ({
        ...acc,
        ['&' + ' > :last-child'.repeat(idx + 1)]: halfMarginBelow,
      })),
    },
    smallIcon: {
      width: 18,
    },
    detachedDrawer: (open, hasItems) => ({
      borderLeft: '8px solid',
      borderLeftColor: hasItems ? 'red.900' : 'gray.400',
      ...(open && {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        borderRight: '8px solid',
        borderRightColor: 'red.900',
        borderBottom: '8px solid',
        borderBottomColor: 'red.900',
      }),
    }),
    detachedChildren: {
      marginLeft: 1,
    },
    detachedColumnHeader: {
      padding: 1,
      paddingTop: 2,
      fontWeight: 'bold',
      backgroundColor: 'red.900',
      color: 'white',
    },
    detachedStartDate: {
      fontWeight: 'bold',
    },
  };
};

export default RuleVersionExplorerView;
